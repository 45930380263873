<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="人事人才培训服务" @moTitltFn="navPanel"></MoTitle>
    <div class="moPtContainer">
      <!-- 头部 -->
      <div class="moTitle center">
          <h1 style="color:#fff;font-size: 1.5rem;margin-bottom:20px">人事人才培训服务</h1>
          <button class="showBtn" @click="$router.push('/moShow')">立即预约演示</button>
      </div>

      <div class="contentBgColor center">
        <div class="ptDescContent">
          <img src="@/assets/product/productRsrcpx/rsrcDesc.png" alt="" style="width:100%;border-radius: 10px 10px 0 0 ;">
          <div class="ptDescContentText">
            新时代高校人事人才管理能力提升专题培训班提供了一个高水平高层次的分享交流平台，旨在服务于高等学校、科研院所的人事工作者，为深入学习党的二十大精神，贯彻落实新时代人才工作的新理念新战略新举措，进一步加强新时代高校人事工作者队伍建设，提升人事工作者的政治理论素养和专业研究能力，推动高校人事人才工作高质量发展。培训将助力参与者在深化改革、突破传统中共同开创人才辈出、人尽其才、人尽其用的新局面，推动新时代高校人事人才工作再上新台阶。
          </div>
        </div>
      </div>
      <div class="contentBgColor center">
        <h2 class="h2Desc" style="text-align:center">新时代高校人事人才管理能力提升专题培训班（第一期）</h2>
        <span class="spanDesc"></span>
        <div style="margin-top:20px">
          <img v-for="(item,index) in firstList" :key="index" :src="item" alt="" class="pxImg">
        </div>
      </div>
      <div class="contentBgColor center">
        <h2 class="h2Desc" style="text-align:center">新时代高校人事人才管理能力提升专题培训班（第二期）</h2>
        <span class="spanDesc"></span>
        <div style="margin-top:20px">
          <img v-for="(item,index) in secondList" :key="index" :src="item" alt="" class="pxImg">
        </div>
      </div>
      <MoBottom></MoBottom>
      <MoFoot></MoFoot>
    </div>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
export default {
  name: 'moProductRsrcpxfw',
  components: {
    MoTitle
  },
  data () {
    return {
      isScroll: false,
      firstList: [
        require('@/assets/product/productRsrcpx/one_First.png'),
        require('@/assets/product/productRsrcpx/one_Two.png'),
        require('@/assets/product/productRsrcpx/one-Three.png'),
        require('@/assets/product/productRsrcpx/one-Four.png'),
        require('@/assets/product/productRsrcpx/one-five.png'),
        require('@/assets/product/productRsrcpx/one-Six.png')
      ],
      secondList: [
        require('@/assets/product/productRsrcpx/Second_one.png'),
        require('@/assets/product/productRsrcpx/Second-Two.png'),
        require('@/assets/product/productRsrcpx/Second-Three.png'),
        require('@/assets/product/productRsrcpx/Second-Four.png'),
        require('@/assets/product/productRsrcpx/Second-Five.png'),
        require('@/assets/product/productRsrcpx/Second-Six.png')
      ]
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/product/productRsrcpxfw')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/product/productRsrcpx/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.moPtContainer{
  padding-top: 2.75rem;
}
.ptDescContent{
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.15);
  border-radius: 10px;
  margin: 20px 0;
}
.ptDescContentText{
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 26px;
  text-shadow: 0px 8px 24px rgba(0,0,0,0.15);
  padding: 20px;
  box-sizing: border-box;
}

.pxImg{
  width:100%;
  margin-bottom: 20px;
}
</style>
